export const smoothScroll = (idElem, duration) => {
  let target;
  let targetPosition;
  let startPosition;
  let startTime = null;

  const ease = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animation = (currentTime) => {
    if (startTime === null) {
      startTime = currentTime;
    }
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPosition, targetPosition, duration);
    //const run = ease(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  };

  // if there's a / inside the idElem, then the id is not correct (do nothing)
  if (!idElem.match(/\//)) {
    target = document.querySelector(idElem);

    if (typeof target === "object" && target !== null) {
      targetPosition = target.getBoundingClientRect().top - 60;
      startPosition = window.pageYOffset;

      requestAnimationFrame(animation);
    }
  }
};
