import React from "react";
// Constants
import constants from "../../constants";
// Material UI
import Grid from "@material-ui/core/Grid";
// Components
import Paragraph from "../Shared/Paragraph/Paragraph";
// Style
import "./scss/Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <Grid
        container
        justify="space-between"
        className="footer-max-width footer__container-grid"
      >
        <Grid item xs={12} md={4} className="footer__copyright">
          <Grid container>
            <Grid item xs={12}>
              <Paragraph
                arrayStringKeysTransl={["FOOTER.AUTHOR"]}
                bold
                marginType=""
              />
              <Paragraph
                arrayStringKeysTransl={["FOOTER.COPYRIGHT"]}
                bold
                marginType="reduced"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="space-between">
                <Grid item xs={12} md={5}>
                  <a href={constants.LINKS.COOKIE_POLICY}>
                    <Paragraph
                      arrayStringKeysTransl={["FOOTER.COOKIE_POLICY"]}
                    />
                  </a>
                </Grid>
                <Grid item xs={12} md={5}>
                  <a href={constants.LINKS.PRIVACY_POLICY}>
                    <Paragraph
                      arrayStringKeysTransl={["FOOTER.PRIVACY_POLICY"]}
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="footer__contact-info">
            <Paragraph
              arrayStringKeysTransl={["FOOTER.TELEPHONE"]}
              bold
              marginType=""
            />
            <Paragraph
              arrayStringKeysTransl={["FOOTER.EMAIL"]}
              bold
              marginType="reduced"
            />
            <Paragraph
              arrayStringKeysTransl={["FOOTER.ADDRESS"]}
              bold
              marginType="reduced"
            />
          </div>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
