import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Constants
import constants from "../../constants";
// Context
import { useHeaderContext } from "../Core/Context/HeaderContext";
// Material UI
import Grid from "@material-ui/core/Grid";
// Components
import Image from "../Shared/Image/Image";
import CreateListElements from "../Shared/CreateListElements/CreateListElements";
import HeaderVoice from "./HeaderVoice/HeaderVoice";
import MobileHeader from "./MobileHeader/MobileHeader";
// Icons
import { ReactComponent as MenuIcon } from "./scss/hamburger_menu.svg";
// Style
import "./scss/Header.scss";

const Header = () => {
  const { t } = useTranslation();
  const { headerState, setHeaderState } = useHeaderContext();

  const handleOpenCloseMenu = (closed = undefined) => {
    setHeaderState(closed === undefined ? !headerState : closed);
  };

  return (
    <React.Fragment>
      <header className="header">
        <Grid container justify="space-evenly">
          <Grid item xs={8} md={4} lg={3}>
            <Link to="/#home" onClick={() => handleOpenCloseMenu(false)}>
              <div className="header__logo-section">
                <Image
                  urlSrcImage={constants.IMAGES.JPG.LOGO}
                  customStyleClass="header-logo-image"
                />
                <h5 className="header__logo-section__name-company">
                  {t("HEADER.COMPANY_NAME")}
                </h5>
              </div>
            </Link>
          </Grid>
          <Grid item xs={1} md={7}>
            <div className="header__container-list-voices">
              <ul className="header__list-voices">
                <CreateListElements
                  listOfElements={constants.HEADER}
                  SingleComponentElement={HeaderVoice}
                />
              </ul>
            </div>
            <div className="header__container-hamburger-icon">
              <MenuIcon
                className="header__hamburger-menu-icon"
                onClick={handleOpenCloseMenu}
              />
            </div>
          </Grid>
        </Grid>
      </header>
      <MobileHeader />
    </React.Fragment>
  );
};

export default Header;
