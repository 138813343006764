import React from "react";
import { useTranslation } from "react-i18next";
// Components
import Paragraph from "../../Paragraph/Paragraph";
// Style
import "./scss/TextArea.scss";

const TextArea = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    id = "",
    placeholderKeyTransl = "",
    error = {},
    name = "textarea",
    required = false,
  } = {
    ...props,
  };

  return (
    <div>
      <Paragraph
        arrayStringKeysTransl={[error.message]}
        bold
        marginType=""
        customClass={`error-message-textarea${
          error.state && name === error.name ? "--show" : "--hide"
        }`}
      />
      <textarea
        className="textarea"
        name=""
        id={id}
        ref={ref}
        cols="30"
        rows="8"
        placeholder={t(placeholderKeyTransl)}
        required={required}
      ></textarea>
    </div>
  );
});

export default TextArea;
