import React from "react";
// Constants
import constants from "../../constants";
// Components
import Heading from "../Shared/Heading/Heading";
import SingleTeamMember from "./SingleTeamMember/SingleTeamMember";
import CreateGroupsElements from "../Shared/CreateListElements/CreateGroupsElements";

const TeamPage = () => {
  return (
    <section className="team-section">
      <Heading
        titleKeyTransl="TEAM_PAGE.TITLE"
        subtitleKeyTransl="TEAM_PAGE.SUBTITLE"
        contentCenter
      />
      <div className="container-content">
        <CreateGroupsElements
          listGroupsOfElements={constants.TEAM_PAGE}
          SingleComponentElement={SingleTeamMember}
          spacingValue={6}
          numberColumnsSingleElementXs={12}
          numberColumnsSingleElementMd={2}
          numberColumnsSingleElementLg={3}
        />
      </div>
    </section>
  );
};

export default TeamPage;
