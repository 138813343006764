import React from "react";
// Components
import Image from "../Image/Image";
// Style
import "./scss/ObliqueBackground.scss";

const ObliqueBackground = ({
  typeOfBackground = "oblique-background",
  urlBackgroundImage = "",
  urlMobileVersioneImage = "",
  coverEffect = false,
  children,
}) => (
  <div className="container-for-background">
    {children}
    {urlBackgroundImage.length > 0 ? (
      <Image
        urlSrcImage={urlBackgroundImage}
        urlMobileVersioneImage={urlMobileVersioneImage}
        customStyleClass={`backgr-standard ${
          coverEffect ? "cover-background" : ""
        }`}
      />
    ) : (
      <div className={typeOfBackground}></div>
    )}
  </div>
);

export default ObliqueBackground;
