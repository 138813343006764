import React from "react";
// Material UI
import Grid from "@material-ui/core/Grid";
// Components
import Image from "../../../Shared/Image/Image";

const SingleSponsor = ({ urlSrcImage = "" }) => {
  return (
    <Grid item xs={10} md={3} className="single-sponsor-logo">
      <Image urlSrcImage={urlSrcImage} sizeClassName="sponsor-image" centered />
    </Grid>
  );
};

export default SingleSponsor;
