import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({
  useInternalLink = true,
  linkPath,
  children,
  ...rest
}) => {
  return useInternalLink ? (
    <Link to={linkPath} {...rest}>
      {children}
    </Link>
  ) : (
    <a href={linkPath} target="blank">
      {children}
    </a>
  );
};

export default CustomLink;
