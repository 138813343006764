import React from "react";
// Constants
import constants from "../../../constants";
// Components
import Heading from "../../Shared/Heading/Heading";
import TextImage from "../../Shared/TextImage/TextImage";
import CreateListElements from "../../Shared/CreateListElements/CreateListElements";
// Style
import "./scss/Competence.scss";

const Competence = () => {
  return (
    <section id="cosa_facciamo">
      <Heading titleKeyTransl="LANDING_PAGE.COMPETENCE.TITLE" contentCenter />
      <div className="container-content">
        <CreateListElements
          listOfElements={constants.COMPETENCE}
          SingleComponentElement={TextImage}
        />
      </div>
    </section>
  );
};

export default Competence;
