import React from "react";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
// Style
import "./scss/Image.scss";

const useStyles = makeStyles((theme) => ({
  imageDesktop: ({ doubleImage }) =>
    doubleImage
      ? {
          display: "none",
          [theme.breakpoints.up("xs")]: {
            display: "block",
          },
        }
      : "",
  imageMobile: ({ doubleImage }) =>
    doubleImage
      ? {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        }
      : "",
}));

const Image = ({
  urlSrcImage = "",
  urlMobileVersioneImage = "",
  titleKeyTransl = "",
  altKeyTransl = "",
  sizeClassName = "",
  centered = false,
  customStyleClass = "",
}) => {
  const classes = useStyles({ doubleImage: urlMobileVersioneImage.length > 0 });

  return (
    <React.Fragment>
      <img
        className={`base-image-style ${sizeClassName} ${
          centered ? "image-center" : ""
        } ${customStyleClass} ${classes.imageDesktop}`}
        src={urlSrcImage}
        alt={altKeyTransl}
        title={titleKeyTransl}
      />
      {urlMobileVersioneImage.length > 0 ? (
        <img
          className={`base-image-style ${sizeClassName} ${
            centered ? "image-center" : ""
          } ${customStyleClass} ${classes.imageMobile}`}
          src={urlMobileVersioneImage}
          alt={altKeyTransl}
          title={titleKeyTransl}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Image;
