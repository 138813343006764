import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SendEmailIcon } from "../../../icons/send_arrow.svg";
import { ReactComponent as ArrowLinkIcon } from "../../../icons/arrow_right.svg";
// Style
import "./scss/Button.scss";

const CustomButton = ({
  textButtonKeyTransl = "",
  centered = true,
  customStyleButton = "",
  typeOfButton = "link",
}) => {
  const { t } = useTranslation();

  const getIcon = (buttonType) => {
    switch (buttonType) {
      case "link":
        return <ArrowLinkIcon className="icon-button" />;
      case "send":
        return <SendEmailIcon className="icon-button--send" />;
      default:
        return null;
    }
  };

  return (
    <button
      className={`button-standard ${
        centered ? "button-centered" : ""
      } ${customStyleButton}`}
    >
      <span className="button-standard__flex-container">
        <span>{t(textButtonKeyTransl)}</span>
        {getIcon(typeOfButton)}
      </span>
    </button>
  );
};

export default CustomButton;
