import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { smoothScroll } from "../../../helpers";

const SmoothScrolling = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const idElement = location.hash;
    if (idElement.length > 0) {
      smoothScroll(idElement, 1000);
    }
  }, [children, location]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default SmoothScrolling;
