import React from "react";
// Constants
import constants from "../../../constants";
// Material UI
import Grid from "@material-ui/core/Grid";
// Components
import Heading from "../../Shared/Heading/Heading";
import CreateListElements from "../../Shared/CreateListElements/CreateListElements";
import SingleSponsor from "./SingleSponsor/SingleSponsor";
// Style
import "./scss/Sponsor.scss";
import ObliqueBackground from "../../Shared/ObliqueBackground/ObliqueBackground";

const Sponsor = () => {
  return (
    <ObliqueBackground typeOfBackground="backgr-standard--gray">
      <section className="sponsor-section">
        <Heading
          titleKeyTransl="LANDING_PAGE.SPONSOR.TITLE"
          contentCenter
          titleSizeReduced
        />
        <div className="sponsor-section__all-sponsor container-content--sponsor">
          <Grid container justify="space-evenly" align="center">
            <CreateListElements
              listOfElements={constants.SPONSOR}
              SingleComponentElement={SingleSponsor}
            />
          </Grid>
        </div>
      </section>
    </ObliqueBackground>
  );
};

export default Sponsor;
