import React from "react";
// Material UI
import Grid from "@material-ui/core/Grid";
// Components
import Heading from "../../../Shared/Heading/Heading";
import Image from "../../../Shared/Image/Image";
// Style
import "./scss/SingleCardOffer.scss";

const SingleCardOffer = ({
  iconSrcUrl = "",
  titleKeyTransl = "",
  textKeyTransl = "",
}) => {
  return (
    <Grid item xs={12} sm={8} md={3}>
      <div className="single-card-offer">
        <Image
          customStyleClass="single-card-offer__image"
          urlSrcImage={iconSrcUrl}
        />
        <Heading
          titleKeyTransl={titleKeyTransl}
          subtitleKeyTransl={textKeyTransl}
          titleSizeReduced
        />
      </div>
    </Grid>
  );
};

export default SingleCardOffer;
