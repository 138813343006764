import React, { memo } from "react";
import { useLocation } from "react-router-dom";
// Context
import { useHeaderContext } from "../../Core/Context/HeaderContext";
// Components
import Paragraph from "../../Shared/Paragraph/Paragraph";
import Heading from "../../Shared/Heading/Heading";
import Link from "../../Shared/Link/Link";

const HeaderVoice = memo(
  ({ textKeyTransl = "", mobileVersion = false, linkPath = "/" }) => {
    const location = useLocation();
    const { setHeaderState } = useHeaderContext();

    const closeMenu = () => {
      setHeaderState(false);
    };

    const isThisLinkSelected = () => {
      // NOTE: The () allows the code to work correctly
      // location.pathname === "/" && (location.hash === "home" || location.hash.length === 0) ==> pathname === '/' && (url has => /#home || url is only => /)
      // location.pathname === linkPath ==> / === /#home || /team === /team || /team === /servizi
      // location.hash.length === 0 ==> check if the url has an hash (/#home)
      return (
        (location.pathname === "/" &&
          (location.hash === "home" || location.hash.length === 0) &&
          linkPath === "/#home") ||
        (location.pathname === linkPath && location.hash.length === 0) ||
        location.hash === linkPath.replace("/", "")
      );
    };

    return (
      <li
        className={`header-single-voice ${
          isThisLinkSelected() ? "header-single-voice--selected" : ""
        }`}
      >
        <Link linkPath={linkPath} onClick={closeMenu}>
          {mobileVersion ? (
            <Heading titleKeyTransl={textKeyTransl} titleSizeReduced={true} />
          ) : (
            <Paragraph
              arrayStringKeysTransl={[textKeyTransl]}
              bold
              marginType=""
              colorWhite
            />
          )}
        </Link>
      </li>
    );
  }
);

export default HeaderVoice;
