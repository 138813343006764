import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// Constants
import constants from "../../../constants";
import api from "../../../constants/api.json";
import { publicAPI } from "../../../constants/axiosRequests";
// Material UI
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// Components
import Heading from "../../Shared/Heading/Heading";
import Input from "../../Shared/Form/Input/Input";
import TextArea from "../../Shared/Form/TextArea/TextArea";
import Button from "../../Shared/Button/Button";
import ObliqueBackground from "../../Shared/ObliqueBackground/ObliqueBackground";
// Style
import "./scss/FormEmail.scss";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const FormEmail = () => {
  const { t } = useTranslation();

  const [infoResultBarState, setInfoResultBarState] = useState({
    status: false,
    typeMessage: "error",
  });
  const [errorFormState, setErrorFormState] = useState({});

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const objectRef = useRef(null);
  const messageRef = useRef(null);

  const isFieldFilled = (valueField) => {
    return valueField.length > 0 && valueField.match(/[a-zA-Z]/);
  };

  const isEmailValid = (email) => {
    return email.match(
      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setInfoResultBarState(false);
  };

  const handleErrorValueField = (event, errorType, errorMessageKeyTransl) => {
    event.preventDefault();
    setErrorFormState({
      state: true,
      name: errorType,
      message: errorMessageKeyTransl,
    });
  };

  const getToken = async () => {
    return publicAPI
      .get(api.GET_TOKEN)
      .then((res) => res.data.token)
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.log(err);
        }
        return "";
      });
  };

  const sendEmail = async (name, mail, subject, text, token) => {
    return publicAPI
      .post(
        api.SEND_EMAIL,
        {
          name,
          mail,
          subject,
          text,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => res.status)
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.error(err);
        }
        return err.response.status;
      });
  };

  const handleOnSubmit = async (event) => {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const object = objectRef.current.value;
    const message = messageRef.current.value;

    event.preventDefault();

    if (isFieldFilled(name)) {
      if (isFieldFilled(object)) {
        if (isFieldFilled(message)) {
          if (isEmailValid(email)) {
            setInfoResultBarState({
              status: true,
              typeMessage: "progress",
            });

            const token = await getToken();

            if (process.env.NODE_ENV === "development") {
              console.log(token);
            }

            const sendStatusResponse = await sendEmail(
              name,
              email,
              object,
              message,
              token
            );

            if (sendStatusResponse === 200) {
              setInfoResultBarState({
                status: true,
                typeMessage: "success",
              });
              // refresh the page
              setTimeout(() => window.location.reload(), 1000);
            } else {
              setInfoResultBarState({
                status: true,
                typeMessage: "error",
              });
            }
          } else {
            handleErrorValueField(event, "email", "MESSAGES.ERROR.EMAIL");
          }
        } else {
          handleErrorValueField(event, "message", "MESSAGES.ERROR.MESSAGE");
        }
      } else {
        handleErrorValueField(event, "object", "MESSAGES.ERROR.OBJECT");
      }
    } else {
      handleErrorValueField(event, "name", "MESSAGES.ERROR.NAME");
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        open={infoResultBarState.status}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        {infoResultBarState.typeMessage === "progress" ? (
          <Alert onClose={handleClose} severity="info">
            {t("MESSAGES.PROGRESS.SENDING_EMAIL")}
          </Alert>
        ) : infoResultBarState.typeMessage === "success" ? (
          <Alert onClose={handleClose} severity="success">
            {t("MESSAGES.SUCCESS.EMAIL_SENT")}
          </Alert>
        ) : infoResultBarState.typeMessage === "error" ? (
          <Alert onClose={handleClose} severity="error">
            {t("MESSAGES.ERROR.EMAIL_NOT_SENT")}
          </Alert>
        ) : null}
      </Snackbar>
      <ObliqueBackground>
        <section className="form-email-section">
          <Heading
            titleKeyTransl="LANDING_PAGE.FORM_EMAIL.TITLE"
            contentCenter
            colorWhite
          />
          <form
            action=""
            className="container-content"
            onSubmit={handleOnSubmit}
          >
            <Grid container className="container-grid-form">
              <Grid item xs={12} sm={10} md={5} lg={4}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Input
                      labelKeyTransl="FORM.PLACEHOLDERS.NAME"
                      required
                      error={errorFormState}
                      name="name"
                      ref={nameRef}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      labelKeyTransl="FORM.PLACEHOLDERS.EMAIL"
                      useMargin
                      required
                      error={errorFormState}
                      name="email"
                      ref={emailRef}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      labelKeyTransl="FORM.PLACEHOLDERS.OBJECT"
                      required
                      error={errorFormState}
                      name="object"
                      ref={objectRef}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={10} md={5} lg={4}>
                <TextArea
                  placeholderKeyTransl="FORM.PLACEHOLDERS.WHAT_TO_ASK"
                  ref={messageRef}
                  error={errorFormState}
                  name="message"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  textButtonKeyTransl="BUTTONS.FORM_EMAIL"
                  urlSrcIcon={constants.IMAGES.SVG.BUTTON_SEND_ARROW}
                  customStyleButton="button-style--on-dark-background"
                  typeOfButton="send"
                />
              </Grid>
            </Grid>
          </form>
        </section>
      </ObliqueBackground>
    </React.Fragment>
  );
};

export default FormEmail;
