import React from "react";
// Constants
import constants from "../../constants";
// Components
import Heading from "../Shared/Heading/Heading";
import CreateListElements from "../Shared/CreateListElements/CreateListElements";
import TextImage from "../Shared/TextImage/TextImage";

const ServicesPage = () => {
  return (
    <section className="services-section">
      <Heading
        titleKeyTransl="SERVICES_PAGE.TITLE"
        subtitleKeyTransl="SERVICES_PAGE.SUBTITLE"
        contentCenter
      />
      <CreateListElements
        listOfElements={constants.SERVICES_PAGE}
        SingleComponentElement={TextImage}
      />
    </section>
  );
};

export default ServicesPage;
