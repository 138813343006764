import React, { useState, useEffect, Fragment } from "react";

const CreateListElements = ({
  listOfElements = [],
  SingleComponentElement = null,
}) => {
  const [HTMLListState, setHTMLListState] = useState([]);

  const createHTMLListElements = (list) => {
    return list.map((item, index) => (
      <SingleComponentElement {...item} key={index} />
    ));
  };

  useEffect(() => {
    if (Array.isArray(listOfElements) && listOfElements.length > 0) {
      const HTMLList = createHTMLListElements(listOfElements);
      setHTMLListState(HTMLList);
    }
  }, [listOfElements, SingleComponentElement]);

  return <Fragment>{HTMLListState}</Fragment>;
};

export default CreateListElements;
