import React from "react";
// Constants
import constants from "../../constants";
// Components
import Motto from "./Motto/Motto";
import Sponsor from "./Sponsor/Sponsor";
import Offers from "./Offers/Offers";
import LinkServices from "./LinkServices/LinkServices";
import Competence from "./Competence/Competence";
import FormEmail from "./FormEmail/FormEmail";
import TimeTablesAndLocation from "./TimeTablesAndLocation/TimeTablesAndLocation";

const LandingPage = () => {
  return (
    <React.Fragment>
      <Motto />
      <Sponsor />
      <Offers />
      <LinkServices
        titleKeyTransl="LANDING_PAGE.LINK_SERVICES.TITLE"
        linkToPage={constants.LINKS.HEADER.SERVICES_PAGE}
        textButtonKeyTransl="BUTTONS.LINK_PAGE_SERVICES"
      />
      <Competence />
      <FormEmail />
      <TimeTablesAndLocation />
    </React.Fragment>
  );
};

export default LandingPage;
