import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
// Constants
import constants from "../../../constants";
// Context
import { useHeaderContext } from "../../Core/Context/HeaderContext";
// Components
import CreateListElements from "../../Shared/CreateListElements/CreateListElements";
import HeaderVoice from "../HeaderVoice/HeaderVoice";
// Style
import "./scss/MobileHeader.scss";

const MobileHeader = () => {
  const { headerState } = useHeaderContext();

  return (
    <TransitionGroup>
      <CSSTransition key={headerState} timeout={300} classNames="left">
        <div className={`mobile-header${headerState ? "--open" : ""}`}>
          <ul className="mobile-header__list-voices">
            <CreateListElements
              listOfElements={constants.HEADER_MOBILE}
              SingleComponentElement={HeaderVoice}
            />
          </ul>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default MobileHeader;
