import React from "react";
// Constants
import constants from "../../../constants";
// Components
import Heading from "../../Shared/Heading/Heading";
import SingleCardOffer from "./SingleCardOffer/SingleCardOffer";
import ContainerGridElements from "../../Shared/CreateListElements/ContainerGridElements";
// Style
import "./scss/Offers.scss";

const Offers = () => {
  return (
    <section className="offers-section distance-mobile" id="promozioni">
      <Heading
        titleKeyTransl="LANDING_PAGE.OFFERS.TITLE"
        subtitleKeyTransl="LANDING_PAGE.OFFERS.SUBTITLE"
        contentCenter
      />
      <ContainerGridElements
        classNameContainer="container-content"
        listOfElements={constants.OFFERS.NORMAL_OFFERS}
        singleComponentOfList={SingleCardOffer}
      />
    </section>
  );
};

export default Offers;
