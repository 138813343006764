import React from "react";
// Material UI
import Grid from "@material-ui/core/Grid";
// Components
import CreateListElements from "./CreateListElements";

const ContainerGridElements = ({
  classNameContainer = "",
  justifyGrid = "space-evenly",
  listOfElements = [],
  singleComponentOfList = null,
}) => {
  return (
    <div className={classNameContainer}>
      <Grid container justify={justifyGrid}>
        <CreateListElements
          listOfElements={listOfElements}
          SingleComponentElement={singleComponentOfList}
        />
      </Grid>
    </div>
  );
};

export default ContainerGridElements;
