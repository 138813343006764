import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
// Constants
import constants from "../../constants";
// Context
import { HeaderContextProvider } from "../Core/Context/HeaderContext";
// Components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Home from "../LandingPage/LandingPage";
import ServicesPage from "../ServicesPage/ServicesPage";
import TeamPage from "../TeamPage/TeamPage";
import ScrollToTop from "./ScrollToTop";
import PublicRoute from "./PublicRoute";
import Error404 from "../Shared/Error404/Error404";
// Style
import "./scss/RouteStyle.scss";

const Routes = () => {
  return (
    <HashRouter>
      <React.Fragment>
        <ScrollToTop />
        <HeaderContextProvider>
          <Header />
        </HeaderContextProvider>
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition
                key={location.pathname}
                timeout={constants.ANIMATION_TIMES.PAGES_TRANSITION}
                classNames="fade"
              >
                <Switch location={location}>
                  <PublicRoute
                    path={constants.LINKS.HEADER.HOME}
                    component={Home}
                    exact
                    smoothScroll
                  />
                  <PublicRoute
                    path={constants.LINKS.HEADER.SERVICES_PAGE}
                    component={ServicesPage}
                    exact
                  />
                  <PublicRoute
                    path={constants.LINKS.HEADER.TEAM_PAGE}
                    component={TeamPage}
                    exact
                  />
                  <PublicRoute
                    path={constants.LINKS.HEADER.HOME}
                    component={Error404}
                  />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
        <Footer />
      </React.Fragment>
    </HashRouter>
  );
};

export default Routes;
