import React from "react";
import { useTranslation } from "react-i18next";
// Material UI
import TextField from "@material-ui/core/TextField";
import { StylesProvider } from "@material-ui/core/styles";
// Component
import Paragraph from "../../Paragraph/Paragraph";
// Style
import "./scss/Input.scss";

const Input = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    id = "",
    labelKeyTransl = "",
    required = false,
    useMargin = false,
    error = null,
    name = "input",
  } = { ...props };

  return (
    <div className={`${useMargin ? "custom-input-margin" : ""}`}>
      <StylesProvider injectFirst>
        <TextField
          id={id}
          label={t(labelKeyTransl)}
          variant="outlined"
          inputRef={ref}
          required={required}
          error={typeof error !== "boolean" ? false : error.state}
        />
      </StylesProvider>
      <Paragraph
        arrayStringKeysTransl={[error.message]}
        bold
        marginType=""
        customClass={`error-message-email${
          error.state && name === error.name ? "--show" : "--hide"
        }`}
      />
    </div>
  );
});

export default Input;
