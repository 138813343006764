import React, { useState, createContext, useContext } from "react";

export const HeaderContext = createContext();

export const HeaderContextProvider = ({ children }) => {
  const [headerState, setHeaderState] = useState(false);

  return (
    <HeaderContext.Provider
      value={{
        headerState,
        setHeaderState,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);

  return { ...context };
};
