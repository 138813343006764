import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
// Style
import "./scss/Heading.scss";

const Heading = ({
  titleKeyTransl = "",
  subtitleKeyTransl = "",
  contentCenter = false,
  titleSizeReduced = false,
  colorWhite = false,
  useMarginTop = false,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`heading ${contentCenter ? "heading--center" : ""} ${
        colorWhite ? "heading--white" : ""
      } ${useMarginTop ? "heading--margin-top" : ""}`}
    >
      {titleSizeReduced ? (
        <h3 className="heading__title">
          {parse(titleKeyTransl.length > 0 ? t(titleKeyTransl) : "")}
        </h3>
      ) : (
        <h2 className="heading__title">
          {parse(titleKeyTransl.length > 0 ? t(titleKeyTransl) : "")}
        </h2>
      )}
      {subtitleKeyTransl.length > 0 ? (
        <p
          className={`heading__subtitle ${
            useMarginTop ? "heading__subtitle--more-margin" : ""
          }`}
        >
          {parse(subtitleKeyTransl.length > 0 ? t(subtitleKeyTransl) : "")}
        </p>
      ) : null}
    </div>
  );
};

export default Heading;
