import React from "react";
// Constants
import constants from "../../../constants";
// Material UI
import Grid from "@material-ui/core/Grid";
// Components
import Heading from "../../Shared/Heading/Heading";
import CreateListElements from "../../Shared/CreateListElements/CreateListElements";
import Paragraph from "../../Shared/Paragraph/Paragraph";
import SingleTimeRecord from "./SingleTimeRecord/SingleTimeRecord";
import Image from "../../Shared/Image/Image";
import Link from "../../Shared/Link/Link";
// Style
import "./scss/TimeTablesAndLocation.scss";

const TimeTablesAndLocation = () => {
  return (
    <section className="time-tables-section" id="contatti">
      <Heading titleKeyTransl="LANDING_PAGE.LOCATION.TITLE" contentCenter />
      <div className="container-content">
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={7}>
            <Paragraph
              arrayStringKeysTransl={["LANDING_PAGE.LOCATION.ADDRESS"]}
            />
            <Paragraph
              arrayStringKeysTransl={["LANDING_PAGE.LOCATION.CAR_SPOT"]}
            />
            <Link
              useInternalLink={false}
              linkPath={constants.LINKS.GOOGLE_MAPS}
            >
              <Image
                urlSrcImage={constants.IMAGES.PNG.GOOGLE_MAPS}
                customStyleClass="image-google-maps"
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <div className="time-tables-section__times-info">
              <Paragraph
                arrayStringKeysTransl={["LANDING_PAGE.LOCATION.APPOINTMENT"]}
                bold
              />
              <Paragraph
                arrayStringKeysTransl={[
                  "LANDING_PAGE.LOCATION.APPOINTMENT_PHONE_NUMBER",
                  "LANDING_PAGE.LOCATION.EMERGENCY_PHONE_NUMBER",
                ]}
              />
              <Paragraph
                arrayStringKeysTransl={[
                  "LANDING_PAGE.LOCATION.TIMETABLES.TITLE",
                ]}
                bold
              />
              <ul className="time-tables-section__list">
                <CreateListElements
                  listOfElements={constants.TIMETABLES}
                  SingleComponentElement={SingleTimeRecord}
                />
              </ul>
              <Paragraph
                arrayStringKeysTransl={["LANDING_PAGE.LOCATION.CONTINUED_TIME"]}
                bold
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default TimeTablesAndLocation;
