import axios from "axios";
import api from "./api.json";

const baseHeader = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "*",
};

export const publicAPI = axios.create({
  baseURL: api.API_BASEURL,
  headers: { ...baseHeader },
});
