import React from "react";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// Components
import Heading from "../Heading/Heading";
import Image from "../Image/Image";
// Style
import "./TextImage.scss";

const useStyles = makeStyles((theme) => ({
  sideImage: {
    display: "flex",
    justifyContent: "center",
  },
  flexOrderImage: ({ placeImageToLeft }) => ({
    order: placeImageToLeft ? 1 : 2,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  }),
  flexOrderText: ({ placeImageToLeft }) => ({
    order: placeImageToLeft ? 2 : 1,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  }),
}));

const TextImage = ({
  titleKeyTransl = "",
  subtitleKeyTransl = "",
  urlSrcImage = "",
  placeImageToLeft = false,
}) => {
  const classes = useStyles({ placeImageToLeft });

  return (
    <div className="container-content text-image-container">
      <Grid container justify="space-between">
        <Grid item xs={12} md={5} lg={5} className={`${classes.flexOrderText}`}>
          <Heading
            titleKeyTransl={titleKeyTransl}
            subtitleKeyTransl={subtitleKeyTransl}
            titleSizeReduced
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          lg={5}
          className={`${classes.sideImage}${classes.flexOrderImage}`}
        >
          <Image urlSrcImage={urlSrcImage} customStyleClass="side-image" />
        </Grid>
      </Grid>
    </div>
  );
};

export default TextImage;
