import React from "react";
// Constants
import constants from "../../../constants";
// Components
import LinkServices from "../../LandingPage/LinkServices/LinkServices";
import { ReactComponent as Error404Icon } from "./scss/error404.svg";
// Style
import "./scss/Error404.scss";

const Error404 = () => {
  return (
    <div className="container-page">
      <div>
        <Error404Icon className="icon-error-404" />
        <LinkServices
          titleKeyTransl="MESSAGES.ERROR.404"
          textButtonKeyTransl="BUTTONS.LINK_HOME"
          linkToPage={constants.LINKS.HEADER.HOME}
        />
      </div>
      <div className="spacer"></div>
    </div>
  );
};

export default Error404;
