import React from "react";
// Constants
import constants from "../../../constants";
// Components
import ObliqueBackground from "../../Shared/ObliqueBackground/ObliqueBackground";
// Style
import "./scss/Motto.scss";

const Motto = () => {
  return (
    <ObliqueBackground
      urlBackgroundImage={constants.IMAGES.JPG.MOTTO}
      urlMobileVersioneImage={constants.IMAGES.JPG.MOTTO_MOBILE}
      coverEffect
    >
      <section className="motto-section" id="home">
        <div className="motto-section__container-text">
          <h1 className="motto-section__container-text__motto-text">
            Il vostro sorriso
            <br />
            La nostra missione
          </h1>
        </div>
      </section>
    </ObliqueBackground>
  );
};

export default Motto;
