import React from "react";
import { Route } from "react-router-dom";
// Components
import SmoothScrolling from "../Shared/SmoothScrolling/SmoothScrolling";

const PublicRoute = ({
  path = "/",
  component = null,
  smoothScroll = false,
  rest,
}) => {
  return smoothScroll ? (
    <SmoothScrolling>
      <Route path={path} component={component} {...rest} />
    </SmoothScrolling>
  ) : (
    <Route path={path} component={component} {...rest} />
  );
};

export default PublicRoute;
