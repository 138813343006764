import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
// Style
import "./scss/Paragraph.scss";

const Paragraph = ({
  arrayStringKeysTransl = [],
  bold = false,
  marginType = "standard",
  colorWhite = false,
  customClass = "",
}) => {
  const { t } = useTranslation();
  const [paragraphState, setParagraphState] = useState("");

  const translateStringsArray = (stringsArray) => {
    return stringsArray.map((item) => t(item));
  };

  const convertStringsArrayIntoSingleString = (stringsArray) => {
    return bold
      ? `<b class="paragraph--bold${
          marginType === "standard" || marginType === "reduced"
            ? `--margin--${marginType}`
            : ""
        } ${
          colorWhite ? "paragraph--color-white" : ""
        } ${customClass}">${stringsArray.join("<br />")}</b>`
      : `<p class="paragraph--standard${
          marginType === "standard" || marginType === "reduced"
            ? `--margin--${marginType}`
            : ""
        } ${
          colorWhite ? "paragraph--color-white" : ""
        } ${customClass}">${stringsArray.join("<br />")}</p>`;
  };

  useEffect(() => {
    if (
      Array.isArray(arrayStringKeysTransl) &&
      arrayStringKeysTransl.length > 0
    ) {
      const parag = convertStringsArrayIntoSingleString(
        translateStringsArray(arrayStringKeysTransl)
      );
      setParagraphState(parag);
    }
  }, [arrayStringKeysTransl]);

  return parse(paragraphState);
};

export default Paragraph;
