import React, { useState, useEffect, Fragment } from "react";
// Material UI
import Grid from "@material-ui/core/Grid";

const CreateGroupsElements = ({
  listGroupsOfElements = [],
  SingleComponentElement = null,
  spacingValue = 6,
  numberColumnsSingleElementXs = 12,
  numberColumnsSingleElementMd = 4,
  numberColumnsSingleElementLg = 4,
}) => {
  const [HTMLListGroupState, setHTMLGroupState] = useState([]);

  const isWidthScreenForDesktop = () => {
    return window.innerWidth > 959;
  };

  const createHTMLGroups = (listOfGroups) => {
    return listOfGroups.map((listOfElements, index) => (
      <Grid
        container
        spacing={isWidthScreenForDesktop() ? spacingValue : 0}
        key={index}
      >
        {listOfElements.map((item, index) => (
          <Grid
            item
            xs={numberColumnsSingleElementXs}
            md={numberColumnsSingleElementMd}
            lg={numberColumnsSingleElementLg}
            key={index}
          >
            <SingleComponentElement {...item} key={index} />
          </Grid>
        ))}
      </Grid>
    ));
  };

  useEffect(() => {
    const listGroupsConst = createHTMLGroups(listGroupsOfElements);
    setHTMLGroupState(listGroupsConst);
  }, [listGroupsOfElements, SingleComponentElement]);

  return <Fragment>{HTMLListGroupState}</Fragment>;
};

export default CreateGroupsElements;
