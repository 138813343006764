import React from "react";
// Constants
import constants from "../../../constants";
// Components
import Heading from "../../Shared/Heading/Heading";
import Button from "../../Shared/Button/Button";
import ObliqueBackground from "../../Shared/ObliqueBackground/ObliqueBackground";
import Link from "../../Shared/Link/Link";

const LinkServices = ({
  titleKeyTransl = "",
  linkToPage = "",
  textButtonKeyTransl = "",
}) => {
  return (
    <ObliqueBackground>
      <section className="link-service-section distance-mobile">
        <Heading titleKeyTransl={titleKeyTransl} contentCenter colorWhite />
        <Link linkPath={linkToPage}>
          <Button
            textButtonKeyTransl={textButtonKeyTransl}
            urlSrcIcon={constants.IMAGES.SVG.BUTTON_ARROW_RIGHT}
            customStyleButton="button-style--on-dark-background"
          />
        </Link>
        <ObliqueBackground />
      </section>
    </ObliqueBackground>
  );
};

export default LinkServices;
