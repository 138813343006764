import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
// Style
import "./scss/SingleTimeRecord.scss";

const SingleTimeRecord = ({ dayOfWeek = "", timeRecord = "" }) => {
  const { t } = useTranslation();

  return (
    <li className="single-time-record">
      <span className="single-time-record__day-week">
        {parse(t(dayOfWeek))}
      </span>
      <span className="single-time-record__time">{parse(t(timeRecord))}</span>
    </li>
  );
};

export default SingleTimeRecord;
