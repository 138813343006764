import React from "react";
// Material UI
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// Components
import Image from "../Image/Image";
import Heading from "../Heading/Heading";
// Style
import "./scss/Dialog.scss";

const CustomDialog = ({
  urlSrcImage = "",
  fullNameKeyTransl = "",
  descriptionKeyTransl = "",
  statusDialog = false,
  handleOnClose = null,
}) => {
  return (
    <Dialog
      className="custom-dialog"
      aria-labelledby="customized-dialog-title"
      open={statusDialog}
    >
      <IconButton aria-label="close" onClick={handleOnClose}>
        <CloseIcon />
      </IconButton>
      <Image urlSrcImage={urlSrcImage} sizeClassName="image-rounded" />
      <Heading
        titleKeyTransl={fullNameKeyTransl}
        subtitleKeyTransl={descriptionKeyTransl}
        titleSizeReduced
        contentCenter
        useMarginTop
      />
    </Dialog>
  );
};

export default CustomDialog;
