import React, { useState } from "react";
// Components
import Image from "../../Shared/Image/Image";
import Paragraph from "../../Shared/Paragraph/Paragraph";
// Icons
import { ReactComponent as Plus } from "../../../icons/plus.svg";
// Style
import "./scss/SingleTeamMember.scss";
import Dialog from "../../Shared/Dialog/Dialog";

const SingleTeamMember = ({
  urlSrcImage = "",
  nameKeyTransl = "",
  descriptionKeyTransl = "",
  specialSizeImage = false,
}) => {
  const [dialogOpenState, setDialogOpenState] = useState(false);

  const handleOpen = () => setDialogOpenState(true);
  const handleClose = () => setDialogOpenState(false);

  return (
    <div className="single-team-member">
      <div className="single-team-member__sub-div">
        <span
          className="single-team-member__sub-div__plus"
          onClick={handleOpen}
        >
          <Plus />
        </span>
        <Image
          urlSrcImage={urlSrcImage}
          sizeClassName={
            specialSizeImage ? "image-rounded--special" : "image-rounded"
          }
        />
        <Paragraph arrayStringKeysTransl={[nameKeyTransl]} bold />
      </div>
      <Dialog
        urlSrcImage={urlSrcImage}
        fullNameKeyTransl={nameKeyTransl}
        descriptionKeyTransl={descriptionKeyTransl}
        statusDialog={dialogOpenState}
        handleOnClose={handleClose}
      />
    </div>
  );
};

export default SingleTeamMember;
